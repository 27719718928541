// eslint-disable-next-line no-unused-vars
import { ref, reactive, watch } from '@vue/composition-api'

export default function others() {
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'icon', sortable: true, searchable: true },
    { key: 'name', sortable: true, searchable: true },
    { key: 'description', sortable: true, searchable: true },
    { key: 'actions' },
  ]

  return {
    tableColumns,
  }
}
